.publications {
    display: flex;
    flex-direction: column;
    gap: 50px 0px;
    list-style-position: inside;
    padding-left: 0px;
}

.publications label {
    font-weight: bold;
}
