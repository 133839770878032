.contacts {
    display: flex;
    flex-direction: column;
    gap: 10px;
    list-style-position: inside;
    padding-left: 0px;
}

.contacts li {
    font-size: large;
    margin: 10px 0px;
};