.blog-text {
    border: 0px solid var(--brown);
    padding: 5px;
    border-radius: 5px;
    background-color: var(--old-page);
    box-sizing: border-box;
}

.blog-text h3, .blog-text p, .blog-text h3, 
.blog-text ul, .blog-text h3, .blog-text ul li {
    padding: 5px;
    background-color: var(--old-page);
}

.blog-text p a {
    background-color: var(--old-page);
}

