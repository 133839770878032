@media screen and (max-width: 900px) {

    .header {
        position: fixed;
        width: 100%;
        height: 40px;
        padding: 8px 16px 8px 16px;
        box-sizing: border-box;
        background-color: var(--old-page);
    }

    #menu-options {
        display: none;
        flex-direction: column;
        flex-wrap: wrap;
        list-style-position: inside;
        width: 50%;
        padding: 10px;
        justify-content: left;
        gap: 20px;
        border: 1px solid black;
        box-shadow: 10px 5px;
        margin: 1px;
    } 

    #menu-icon {
        display: block;
    }

    #menu-options li {
        list-style-type: none;
        color: var(--blue);
        text-align: left;
    }

    #menu-options li a {
        color: var(--blue);
        text-decoration: none;
        padding: 10px;
    }
     
    #menu-options li a:hover {
        color: var(--old-page);
        background-color: var(--gray);
        padding: 10px;
    }
   
}

@media screen and (min-width: 901px) {

    .header {
        position: relative;
        width: 100%;
        height: 40px;
    }

    #menu-options {
        display: flex;
        flex-direction: row;
        gap: 10px;
        flex-wrap: wrap;
        list-style-position: inside;
        padding: 0px;
        justify-content: space-evenly;
    } 

    #menu-icon {
        display: none;
    }

    #menu-options li {
        list-style-type: none;
        color: var(--blue);
    }

    #menu-options li a {
        color: var(--blue);
        text-decoration: none;
        padding: 10px;
    }
     
    #menu-options li a:hover {
        color: var(--new-page);
        background-color: var(--gray);
    }
}

a {
    color: var(--blue);
    text-decoration: none;
    font-weight: normal;
}

a:hover {
    color: var(--blue);
    text-decoration: none;
}
