.works {
    display: flex;
    flex-direction: column;
    list-style-position: inside;
    padding-left: 0px;
    gap: 10px;
}

.works li {
    font-size: large;
    margin: 0px 0px 10px 0px;
};