.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

:root {
  --blue: #0ca5b0;
  --brown: #4e3f30;
  --new-page: #fefeeb;
  --old-page: #f8f4e4;
  --gray: #a5b3aa;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

* {
  background-color: var(--new-page);
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 900px) {
  .content {
    background-color: var(--new-page);
    color: var(--brown);
    padding: 48px 16px;
  }
}

@media screen and (min-width: 901px) {
  .content {
    background-color: var(--new-page);
    color: var(--brown);
    padding: 16px;
  }
}

.content ul li {
  list-style: none;
}
